import React from 'react'
import SbEditable from 'storyblok-react'
import { Field as FormikField } from 'formik'
import { Box, TextareaAutosize } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
const useStyles = makeStyles((theme) => ({
  textArea: {
    padding: '9px 14px',
    fontSize: '38px',
    font: 'inherit',
    borderColor: theme.palette.action.selected,
    marginTop: '20px',
    boxSizing: 'border-box',
    '&:hover': {
      borderColor: theme.palette.secondary.main,
      outline: 'none',
    },
    '&:focus': {
      border: '2px solid',
      borderColor: theme.palette.primary.main,
      '&:hover': {
        borderColor: theme.palette.secondary.main,
      },
      outline: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    },
  },
  textAreaError: {
    borderColor: 'red',
    '&:hover': {
      borderColor: 'red',
    },
    '&:focus': {
      borderColor: 'red',
      '&:hover': {
        borderColor: 'red',
      },
    },
  },
  errorMessage: {
    color: 'red',
  },
}))

const validate = (value, isRequired) => {
  let error
  if (!value && isRequired) {
    error = 'Field Required'
  }
  return error
}

const CustomTextarea = ({ field, form: { touched, errors }, ...props }) => {
  const classes = useStyles()
  const error = touched[field.name] && errors[field.name] && props.required
  const { rows, rowsMax, defaultValue, name, placeholder, required } = props
  return (
    <>
      <TextareaAutosize
        key={props.index}
        rows={rows || 3}
        rowsMax={rowsMax}
        placeholder={placeholder}
        defaultValue={defaultValue}
        required={required}
        name={name}
        onBlur={props.onBlur}
        onChange={props.onChange}
        value={props.value}
        error={error}
        className={`${classes.textArea} ${error && classes.textAreaError}`}
        {...props}
        {...field}
      />
      {error && (
        <Box className={classes.errorMessage}>{errors[field.name]}</Box>
      )}
    </>
  )
}
const MarketoFormTextarea = (props) => {
  const {
    fieldId,
    fieldName,
    required,
    shouldShowPlaceholder = true,
  } = props.blok
  return (
    <SbEditable content={props.blok}>
      <FormikField
        name={fieldId.trim()}
        required={required}
        component={CustomTextarea}
        placeholder={
          shouldShowPlaceholder ? fieldName || 'Please enter a value' : null
        }
        validate={(e) => validate(e, required)}
      />
    </SbEditable>
  )
}
export default MarketoFormTextarea
